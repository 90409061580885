import React from "react";
import AppraisalService from "../../services/AppraisalService";
import UserService from "../../services/UserService";
import GoalTypeService from "../../services/GoalTypeService";
import ManagerFeedbackService from "../../services/ManagerFeedbackService";
import {
  EMPLOYEE_ASESSOR_TYPE,
  MANAGER_ASSESSOR_TYPE,
  FINAL_ASSESSOR_TYPE,
  NO_ASSESSOR_TYPE,
} from "../../constants/assessorTypes";
import {
  APPRAISAL_STATUS_ACTIVE,
  APPRAISAL_STATUS_REVIEW,
  APPRAISAL_STATUS_CLOSED,
} from "../../constants/appraisalStatus";
import AuthenticationService from "../../services/AuthenticationService";
import {
  PERMISSION_ASSESS_EMPLOYEES,
  PERMISSION_SELF_ASSESS,
  PERMISSION_APPRAISAL_EXPORT_ALL,
  PERMISSION_APPRAISAL_EXPORT_OWN,
  PERMISSION_APPRAISAL_EXPORT_TEAM,
  PERMISSION_MANAGE_FEEDBACKS,
} from "../../constants/permissions";
import {
  VIEW_ONLY_MODE,
  ASSESS_MODE,
  REVIEW_MODE,
} from "../../constants/appraisalModes";
import { Breadcrumb, Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import Loading from "../common/display/Loading";
import { ToastContainer } from "react-toastr";
import ErrorComponent from "../common/ErrorComponent";
import { FORBIDDEN_ERROR_CODE } from "../../constants/errorStatusCodes";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import { GoalTable } from "./GoalTable";
import {
  ORGANIZATIONAL_GOAL_TYPE_ID,
  PROJECT_GOAL_TYPE_ID,
  PERSONAL_DEVELOPMENT_GOAL_TYPE_ID,
} from "../../constants/goalTypes";
import { MANAGER_GRADES_DICTIONARY_NUM } from "../../constants/managerFeedbackGradeDictionary";
import PersonalDevelopmentCommentSection from "./PersonalDevelopmentCommentSection";
import { withRouter } from "react-router-dom";
import "./appraisal-preview.css";
import { FormattedMessage } from "react-intl";
import ManagerFeedbackContainer from "../managerfeedback/ManagerFeedbackContainer";
import ManagerFeedbackHeader from "../managerfeedback/ManagerFeedbackHeader";
import "./appraisalManage.css";

class AppraisalManage extends React.Component {
  state = {
    appraisal: undefined,
    targetUser: undefined,
    manager: undefined,
    process: undefined,
    mode: undefined,
    extendedAssessmentGoals: [],
    organizationalGrades: [],
    projectGrades: [],
    personalDevelopmentGrades: [],
    assessments: [],
    employeeAppraisalAssessments: [],
    managerAppraisalAssessments: [],
    personalDevelopmentComment: "",
    goalTypes: [],
    accessDenied: false,
    resourcesLoading: true,
    managerFeedbackQuestions: [],
    rating: 0,
    anonymously: false,
    managerFeedback: [],
    assessorId: "",
    managerFeedbackAndQuestions: [],
    managerFeedbackDrafts: [],
  };

  toastContainer;

  getAppraisalId = () =>
    this.props.appraisalId
      ? this.props.appraisalId
      : this.props.match.params.id;

  componentDidMount() {
    AppraisalService.getProcessUserByAppraisalId(this.getAppraisalId()).then(
      (processUser) =>
        Promise.all([
          UserService.getUserById(processUser.appraisal.targetEmployeeId),
          UserService.getUserById(processUser.appraisal.managerId),
          AppraisalService.getGoalsByAppraisalId(processUser.appraisal.id),
          AppraisalService.getEmployeeAppraisalAssessment(
            processUser.appraisal.id
          ),
          AppraisalService.getManagerAppraisalAssessment(
            processUser.appraisal.id
          ),
          AppraisalService.getFinalApprisalAssessment(processUser.appraisal.id),
          AppraisalService.getOrganizationalGrades(),
          AppraisalService.getProjectGrades(),
          AppraisalService.getPersonalDevelopmentGrades(),
          GoalTypeService.getAllGoalTypes(),
          AuthenticationService.hasPermission(
            this.props.user,
            PERMISSION_MANAGE_FEEDBACKS
          )
            ? ManagerFeedbackService.getManagerFeedbackQuestions()
            : null,
        ])
          .then(
            ([
              targetUser,
              manager,
              goals,
              employeeAppraisals,
              managerAppraisals,
              finalAppraisals,
              organizationalGrades,
              projectGrades,
              personalDevelopmentGrades,
              goalTypes,
              managerFeedbackQuestions,
            ]) => {
              const imageUrls = [];
              [targetUser, manager].forEach((user) => {
                imageUrls.push(
                  UserService.getUserProfilePictureUrlByEmail(
                    user.email,
                    (url) => {
                      user.imageUrl = url;
                    }
                  )
                );
              });
              Promise.all(imageUrls).then(() => {
                this.setState({ ...this.state, targetUser, manager });
              });

              let mode = this.getAppraisalMode(processUser.appraisal);

              let extendedAssessmentGoals = goals
                .filter(
                  (goal) =>
                    this.isGoalCreatedBeforeAppraisal(
                      goal,
                      processUser.appraisal
                    ) &&
                    this.isGoalActive(goal, mode) &&
                    this.hasGoalAssessment(goal, mode, [
                      ...employeeAppraisals,
                      ...managerAppraisals,
                      ...finalAppraisals,
                    ])
                )
                .map((appraisalGoal) => Object.assign({ goal: appraisalGoal }));
              this.handleFetchingEmployeeAssessments(
                extendedAssessmentGoals,
                employeeAppraisals
              );
              this.handleFetchingManagerAssessments(
                extendedAssessmentGoals,
                managerAppraisals
              );
              this.handleFetchingFinalAssessments(
                extendedAssessmentGoals,
                finalAppraisals
              );
              this.setCommentContent(
                processUser.appraisal.assessors.find(
                  (a) => a.type === EMPLOYEE_ASESSOR_TYPE
                ).personalDevelopmentComment
              );
              this.setState({
                mode,
                appraisal: processUser.appraisal,
                targetUser,
                manager,
                process: processUser.process,
                extendedAssessmentGoals,
                organizationalGrades: organizationalGrades,
                projectGrades: projectGrades,
                personalDevelopmentGrades: personalDevelopmentGrades,
                employeeAppraisalAssessments: employeeAppraisals,
                managerAppraisalAssessments: managerAppraisals,
                goalTypes,
                resourcesLoading: false,
                managerFeedbackQuestions,
                assessorId: processUser.appraisal.assessors.find(
                  (assessor) => assessor.type === EMPLOYEE_ASESSOR_TYPE
                ).id,
              });
            }
          )
          .catch(() =>
            UserService.getUserById(
              processUser.appraisal.targetEmployeeId
            ).then((user) =>
              this.setState({
                accessDenied: true,
                targetUser: user,
                process: processUser.process,
                extendedAssessmentGoals: [],
                resourcesLoading: false,
              })
            )
          )
          .then(
            this.getManagerFeedbackDrafts(
              processUser.appraisal.targetEmployeeId,
              processUser.appraisal.assessors.find(
                (assessor) => assessor.type === EMPLOYEE_ASESSOR_TYPE
              ).id
            )
          )
    );
  }
  getManagerFeedbackDrafts = (userId, assessmentId) => {
    if (
      AuthenticationService.hasPermission(
        this.props.user,
        PERMISSION_MANAGE_FEEDBACKS
      )
    ) {
      ManagerFeedbackService.getManagerFeedbackDrafts(
        userId,
        assessmentId
      ).then((feedback) => {
        this.setState({
          managerFeedbackDrafts: feedback.managerFeedback,
        });
        if (feedback.assessorId !== null) {
          this.setState({
            managerFeedback: feedback.managerFeedback,
            anonymously: feedback.anonymous,
          });
        }
      });
    } else {
      userId = null;
      assessmentId = null;
    }
  };

  isGoalCreatedBeforeAppraisal = (goal, appraisal) =>
    new Date(goal.createdAt) <= new Date(appraisal.createdAt);

  isGoalActive = (goal, mode) => (mode === VIEW_ONLY_MODE ? true : goal.active);

  hasGoalAssessment = (goal, mode, assessments) =>
    mode === VIEW_ONLY_MODE
      ? assessments.find((assessment) => assessment.goalId === goal.id)
      : true;

  getAppraisalMode = (appraisal) => {
    if (
      this.props.assessorType !== NO_ASSESSOR_TYPE &&
      appraisal.status !== APPRAISAL_STATUS_CLOSED
    ) {
      if (
        this.props.assessorType !== FINAL_ASSESSOR_TYPE &&
        appraisal.status === APPRAISAL_STATUS_ACTIVE
      ) {
        if (
          (this.props.assessorType === EMPLOYEE_ASESSOR_TYPE &&
            appraisal.assessors.find(
              (assessor) => assessor.type === EMPLOYEE_ASESSOR_TYPE
            ).draft &&
            AuthenticationService.hasPermission(
              this.props.user,
              PERMISSION_SELF_ASSESS
            )) ||
          (this.props.assessorType === MANAGER_ASSESSOR_TYPE &&
            appraisal.assessors.find(
              (assessor) => assessor.type === MANAGER_ASSESSOR_TYPE
            ).draft &&
            AuthenticationService.hasPermission(
              this.props.user,
              PERMISSION_ASSESS_EMPLOYEES
            ))
        ) {
          return ASSESS_MODE;
        }
      } else if (
        this.props.assessorType === FINAL_ASSESSOR_TYPE &&
        appraisal.status === APPRAISAL_STATUS_REVIEW &&
        AuthenticationService.hasPermission(
          this.props.user,
          PERMISSION_ASSESS_EMPLOYEES
        )
      ) {
        return REVIEW_MODE;
      }
    }
    return VIEW_ONLY_MODE;
  };

  /**
   * Helper method, which handle properly into the current state - the assessments,
   * returned from the server for the particular appraisal
   * @param assessments
   */
  handleFetchingEmployeeAssessments = (
    extendedAssessmentGoals,
    assessments
  ) => {
    extendedAssessmentGoals.forEach((extendedAssessmentGoal) => {
      const searchedAssessment = assessments.find(
        (assessment) => assessment.goalId === extendedAssessmentGoal.goal.id
      );
      if (searchedAssessment !== undefined) {
        extendedAssessmentGoal.employeeAssessment = searchedAssessment;
      }
    });
  };

  handleFetchingManagerAssessments = (extendedAssessmentGoals, assessments) => {
    extendedAssessmentGoals.forEach((extendedAssessmentGoal) => {
      const searchedAssessment = assessments.find(
        (assessment) => assessment.goalId === extendedAssessmentGoal.goal.id
      );
      if (searchedAssessment !== undefined) {
        extendedAssessmentGoal.managerAssessment = searchedAssessment;
      }
    });
  };

  handleFetchingFinalAssessments = (extendedAssessmentGoals, assessments) => {
    extendedAssessmentGoals.forEach((extendedAssessmentGoal) => {
      const searchedAssessment = assessments.find(
        (assessment) => assessment.goalId === extendedAssessmentGoal.goal.id
      );
      if (searchedAssessment !== undefined) {
        extendedAssessmentGoal.finalAssessment = searchedAssessment;
      }
    });
  };

  showSuccessNotification = (successMessage) => {
    if (this.toastContainer && successMessage) {
      this.toastContainer.success(successMessage, "", {
        closeButton: true,
      });
    }
  };

  showErrorNotification = (error) =>
    this.toastContainer.error(error.message, "", { closeButton: true });

  handleSubmit = (draft) => {
    const assesor = this.state.appraisal.assessors.find(
      (assessor) => assessor.type === EMPLOYEE_ASESSOR_TYPE
    );
    if (assesor) {
      if (
        AuthenticationService.hasPermission(
          this.props.user,
          PERMISSION_MANAGE_FEEDBACKS
        )
      ) {
        ManagerFeedbackService.submitFeedback(
          this.state.managerFeedback,
          this.state.targetUser.id,
          this.state.targetUser.managerId,
          this.state.assessorId,
          this.state.anonymously
        );
      }
    }
    if (draft) {
      this.submitAppraisal(draft);
    } else {
      if (
        this.props.assessorType === EMPLOYEE_ASESSOR_TYPE &&
        this.state.appraisal.status === APPRAISAL_STATUS_ACTIVE &&
        this.state.mode === ASSESS_MODE
      ) {
        if (!this.validateFeedbackComments(this.state.managerFeedback)) return;
        if (
          this.isSubmissionAllowed() &&
          this.state.managerFeedback.filter(
            (f) => f.score === -1 || f.score === 0 || !f.score
          ).length === 0 &&
          this.state.managerFeedback.length >= 3
        ) {
          this.submitAppraisal(draft);
        } else {
          this.toastContainer.error("All goals must be assessed!", "", {
            closeButton: true,
          });
        }
      } else {
        if (this.isSubmissionAllowed()) {
          this.submitAppraisal(draft);
        } else {
          this.toastContainer.error("All goals must be assessed!", "", {
            closeButton: true,
          });
        }
      }
    }
  };

  validateFeedbackComments = (managerFeedbacks) => {
    return managerFeedbacks.every((feedback) => {
      if (feedback.score !== 3) {
        if (feedback.comment === undefined || feedback.comment.trim() === "") {
          this.toastContainer.error(
            "Comment is mandatory for score different than 3!",
            "",
            {
              closeButton: true,
            }
          );
          return false;
        }
      }
      return true;
    });
  };

  isSubmissionAllowed = () => {
    if (
      this.state.mode === ASSESS_MODE &&
      this.props.assessorType === EMPLOYEE_ASESSOR_TYPE
    ) {
      return (
        this.state.assessments.filter(
          (a) =>
            !this.state.employeeAppraisalAssessments
              .map((eaa) => eaa.goalId)
              .includes(Number(a.goalId))
        ).length +
          this.state.employeeAppraisalAssessments.filter(
            (eaa) => eaa.goalActive
          ).length ===
        this.state.extendedAssessmentGoals.filter((eag) => eag.goal.active)
          .length
      );
    } else if (
      this.state.mode === ASSESS_MODE &&
      this.props.assessorType === MANAGER_ASSESSOR_TYPE
    ) {
      return (
        this.state.assessments.filter(
          (a) =>
            !this.state.managerAppraisalAssessments
              .map((maa) => maa.goalId)
              .includes(Number(a.goalId))
        ).length +
          this.state.managerAppraisalAssessments.filter((maa) => maa.goalActive)
            .length ===
        this.state.extendedAssessmentGoals.filter((eag) => eag.goal.active)
          .length
      );
    } else if (this.state.mode === REVIEW_MODE) {
      return true;
    }

    return false;
  };

  submitAppraisal = (draft) => {
    AppraisalService.submitAppraisal(
      this.state.appraisal.id,
      this.state.assessments,
      this.props.assessorType,
      this.props.assessorType === MANAGER_ASSESSOR_TYPE
        ? ""
        : this.state.personalDevelopmentComment,
      draft
    ).then((response) => {
      if (response.status === 201) {
        if (this.props.assessorType === EMPLOYEE_ASESSOR_TYPE) {
          if (draft) {
            this.showSuccessNotification("Appraisal submitted as draft.");
          } else {
            this.componentDidMount();
            this.showSuccessNotification("Appraisal submitted.");
          }
        } else if (this.props.assessorType === MANAGER_ASSESSOR_TYPE) {
          this.props.history.push({
            pathname: "/manager",
            successNotificationMessage: draft
              ? "Appraisal submitted as draft."
              : "Appraisal submitted.",
          });
        } else {
          this.props.history.push(
            `/users/${this.state.appraisal.targetEmployeeId}/appraisal/goals`
          );
        }
      }
    });
  };

  addAssessment = (goalId, grade) =>
    this.setState((prevState) => ({
      assessments: [
        ...prevState.assessments,
        {
          goalId: goalId,
          grade: grade,
        },
      ],
    }));

  assessment = (event) => {
    if (this.state.assessments.length === 0) {
      this.setState({
        assessments: [
          {
            goalId: event.target.id,
            grade:
              event.target.type === "select-one"
                ? event.target.value
                : this.getGradeAndCommentByGoalId(event.target.id)[0],
            comment:
              event.target.type === "textarea"
                ? event.target.value
                : this.getGradeAndCommentByGoalId(event.target.id)[1],
          },
        ],
      });
      return;
    }
    let assessments = this.state.assessments;
    for (let i = 0; i < assessments.length; i++) {
      if (assessments[i].goalId === event.target.id) {
        assessments[i] = {
          goalId: event.target.id,
          grade:
            event.target.type === "select-one"
              ? event.target.value
              : assessments[i].grade,
          comment:
            event.target.type === "textarea"
              ? event.target.value
              : assessments[i].comment,
        };
        break;
      } else if (i === this.state.assessments.length - 1) {
        this.setState({
          assessments: [
            ...assessments,
            {
              goalId: event.target.id,
              grade:
                event.target.type === "select-one"
                  ? event.target.value
                  : this.getGradeAndCommentByGoalId(event.target.id)[0],
              comment:
                event.target.type === "textarea"
                  ? event.target.value
                  : this.getGradeAndCommentByGoalId(event.target.id)[1],
            },
          ],
        });
      }
    }
  };

  getGradeAndCommentByGoalId = (goalId) => {
    let grade = undefined;
    let comment = undefined;

    if (this.props.assessorType === EMPLOYEE_ASESSOR_TYPE) {
      this.state.extendedAssessmentGoals.find(
        (e) => e.goal.id === Number(goalId)
      ).employeeAssessment
        ? (grade = this.state.extendedAssessmentGoals.find(
            (e) => e.goal.id === Number(goalId)
          ).employeeAssessment.goalTypeGrade.title)
        : (grade = undefined);
      this.state.extendedAssessmentGoals.find(
        (e) => e.goal.id === Number(goalId)
      ).employeeAssessment
        ? (comment = this.state.extendedAssessmentGoals.find(
            (e) => e.goal.id === Number(goalId)
          ).employeeAssessment.comment)
        : (comment = undefined);
    } else if (this.props.assessorType === MANAGER_ASSESSOR_TYPE) {
      this.state.extendedAssessmentGoals.find(
        (e) => e.goal.id === Number(goalId)
      ).managerAssessment
        ? (grade = this.state.extendedAssessmentGoals.find(
            (e) => e.goal.id === Number(goalId)
          ).managerAssessment.goalTypeGrade.title)
        : (grade = undefined);
      this.state.extendedAssessmentGoals.find(
        (e) => e.goal.id === Number(goalId)
      ).managerAssessment
        ? (comment = this.state.extendedAssessmentGoals.find(
            (e) => e.goal.id === Number(goalId)
          ).managerAssessment.comment)
        : (comment = undefined);
    }
    return [grade, comment];
  };

  getDownloadButton = () => {
    if (
      (this.state.appraisal.status === APPRAISAL_STATUS_CLOSED &&
        (AuthenticationService.hasPermission(
          this.props.user,
          PERMISSION_APPRAISAL_EXPORT_ALL
        ) ||
          AuthenticationService.hasPermission(
            this.props.user,
            PERMISSION_APPRAISAL_EXPORT_OWN
          ) ||
          AuthenticationService.hasPermission(
            this.props.user,
            PERMISSION_APPRAISAL_EXPORT_TEAM
          ))) ||
      (this.props.assessorType === MANAGER_ASSESSOR_TYPE &&
        this.state.appraisal.assessors.find(
          (assessor) => assessor.type === MANAGER_ASSESSOR_TYPE
        ).draft &&
        AuthenticationService.hasPermission(
          this.props.user,
          PERMISSION_ASSESS_EMPLOYEES
        ) &&
        this.state.employeeAppraisalAssessments?.length > 0)
    ) {
      return (
        <Row className="mb-3">
          <Col className="text-right">
            <Button
              variant="outline-info"
              onClick={() =>
                AppraisalService.exportAppraisalAsPDF(
                  this.state.appraisal.id,
                  this.state.appraisal.status,
                  this.props.assessorType
                ).catch((error) => this.showErrorNotification(error))
              }
            >
              <FontAwesomeIcon icon={faFileDownload} />
              <span className="ml-2">
                <FormattedMessage id="Appraisal.getAsPdf" />
              </span>
            </Button>
          </Col>
        </Row>
      );
    }
  };

  setCommentContent = (value) =>
    this.setState({ personalDevelopmentComment: value });

  getButtons = () => (
    <Row>
      <Col className="text-right mb-4 mr-1">
        {(this.state.mode === ASSESS_MODE && (
          <>
            <Button
              variant="primary"
              className="mr-2 appraisal-manage-button-relative"
              onClick={() => this.handleSubmit(false)}
            >
              <FormattedMessage id="Appraisal.submit" />{" "}
            </Button>
            <Button
              variant="secondary"
              className="appraisal-manage-button-relative"
              onClick={() => this.handleSubmit(true)}
            >
              <FormattedMessage id="Appraisal.draft" />
            </Button>
          </>
        )) ||
          (this.state.mode === REVIEW_MODE && (
            <Button
              variant="primary"
              className="appraisal-manage-button-relative"
              onClick={() => this.handleSubmit(false)}
            >
              <FormattedMessage id="Appraisal.submit" />{" "}
            </Button>
          ))}
      </Col>
    </Row>
  );

  setAnonymously = () => {
    if (this.state.anonymously === false) {
      this.setState(() => ({
        anonymously: true,
      }));
    }
    if (this.state.anonymously === true) {
      this.setState(() => ({
        anonymously: false,
      }));
    }
  };

  setManagerFeedback = (questionId, comment, score, questionContent) =>
    this.setState((prevState) => {
      const local = prevState.managerFeedback;
      const localIndex = local
        .map((element) => element.questionId)
        .indexOf(questionId);
      if (localIndex !== -1) {
        local.splice(localIndex, 1);
      }
      return {
        managerFeedback: [
          ...local,
          {
            questionId: questionId,
            comment: comment,
            score: score,
            questionContent: questionContent,
          },
        ],
      };
    });

  getManagerFeedback = () =>
    this.props.assessorType === EMPLOYEE_ASESSOR_TYPE &&
    this.state.appraisal.status === APPRAISAL_STATUS_ACTIVE &&
    this.state.mode === ASSESS_MODE &&
    AuthenticationService.hasPermission(
      this.props.user,
      PERMISSION_MANAGE_FEEDBACKS
    ) ? (
      <>
        <ManagerFeedbackHeader
          managerName={this.state.targetUser.managerName}
        />

        {this.state.managerFeedbackDrafts
          ? this.state.managerFeedback
              .sort((a, b) => a.questionId - b.questionId)
              .map((feedback) => (
                <ManagerFeedbackContainer
                  key={feedback.questionId}
                  questionId={feedback.questionId}
                  question={feedback.questionContent}
                  feedback={feedback}
                  setManagerFeedback={this.setManagerFeedback}
                  viewOnly={this.state.mode === VIEW_ONLY_MODE}
                  grades={Object.keys(MANAGER_GRADES_DICTIONARY_NUM).map(
                    (key) => {
                      return {
                        id: key,
                        title: MANAGER_GRADES_DICTIONARY_NUM[key].toString(),
                      };
                    }
                  )}
                />
              ))
          : this.state.managerFeedbackQuestions.map((question) => (
              <ManagerFeedbackContainer
                key={question.id}
                questionId={question.id}
                question={question.content}
                setManagerFeedback={this.setManagerFeedback}
                viewOnly={this.state.mode === VIEW_ONLY_MODE}
                grades={Object.keys(MANAGER_GRADES_DICTIONARY_NUM).map(
                  (key) => {
                    return {
                      id: key,
                      title: MANAGER_GRADES_DICTIONARY_NUM[key].toString(),
                    };
                  }
                )}
              />
            ))}

        <Row style={{ paddingTop: "50px", paddingBottom: "50px" }}>
          <Col className={"text-right"}>
            {this.state.mode !== VIEW_ONLY_MODE && (
              <>
                <div className="custom-checkbox ">
                  <Form.Check
                    type="checkbox"
                    label={<FormattedMessage id="ManagerFeedback.anon" />}
                    checked={this.state.anonymously}
                    onChange={this.setAnonymously}
                  />
                </div>

                <span style={{ fontStyle: "italic", fontSize: "10px" }}>
                  <FormattedMessage id="ManagerFeedback.name" />
                </span>
              </>
            )}
          </Col>
        </Row>
      </>
    ) : (
      <></>
    );

  render() {
    return this.state.resourcesLoading ? (
      <Loading />
    ) : (
      <>
        <ToastContainer
          ref={(ref) => (this.toastContainer = ref)}
          className="toast-top-right"
        />
        {this.state.accessDenied ? (
          <ErrorComponent
            statusCode={FORBIDDEN_ERROR_CODE}
            user={this.props.user}
            message={`The access to this appraisal goals of ${this.state.targetUser.firstName} ${this.state.targetUser.lastName} is restricted`}
          />
        ) : (
          <Container
            fluid
            style={{
              width: "66%",
              marginTop: "1%",
              display: "flex",
              flexDirection: "column",
              padding: 0,
            }}
          >
            {this.props.location.state &&
              this.props.location.state.historyTitle === "history" && (
                <Row>
                  <Breadcrumb>
                    <Breadcrumb.Item
                      onClick={() => this.props.history.push("/reviews")}
                    >
                      <FormattedMessage id="Appraisal.reviews" />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => this.props.history.go(-3)}>
                      {this.props.location.state.reviewTitle}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => this.props.history.go(-2)}>
                      {this.props.location.state.processTitle}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => this.props.history.go(-1)}>
                      <FormattedMessage id="Appraisal.appraisalHistory" />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                      active
                    >{`${this.state.targetUser.firstName.toUpperCase()} ${this.state.targetUser.lastName.toUpperCase()} APPRAISAL`}</Breadcrumb.Item>
                  </Breadcrumb>
                </Row>
              )}
            {this.props.location.state &&
              !this.props.location.state.historyTitle &&
              !this.props.location.state.managerRedirect && (
                <Row>
                  <Breadcrumb>
                    <Breadcrumb.Item
                      onClick={() => this.props.history.push("/reviews")}
                    >
                      <FormattedMessage id="Appraisal.reviews" />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => this.props.history.go(-2)}>
                      {this.props.location.state.reviewTitle}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={() => this.props.history.go(-1)}>
                      {this.props.location.state.processTitle}
                    </Breadcrumb.Item>

                    <Breadcrumb.Item
                      active
                    >{`${this.state.targetUser.firstName.toUpperCase()} ${this.state.targetUser.lastName.toUpperCase()} APPRAISAL`}</Breadcrumb.Item>
                  </Breadcrumb>
                </Row>
              )}

            {this.props.location.state &&
              !this.props.location.state.historyTitle &&
              this.props.location.state.managerRedirect ===
                "managerHistory" && (
                <Row>
                  <Breadcrumb>
                    <Breadcrumb.Item
                      onClick={() => this.props.history.push("/manager")}
                    >
                      <FormattedMessage id="Appraisal.manager" />
                    </Breadcrumb.Item>

                    <Breadcrumb.Item onClick={() => this.props.history.go(-1)}>
                      <FormattedMessage id="Appraisal.appraisalHistory" />{" "}
                    </Breadcrumb.Item>

                    <Breadcrumb.Item
                      active
                    >{`${this.state.targetUser.firstName.toUpperCase()} ${this.state.targetUser.lastName.toUpperCase()} APPRAISAL`}</Breadcrumb.Item>
                  </Breadcrumb>
                </Row>
              )}
            {this.props.location.state &&
              this.props.location.state.managerRedirect === "viewOrAssess" && (
                <Row>
                  <Breadcrumb>
                    <Breadcrumb.Item
                      onClick={() => this.props.history.push("/manager")}
                    >
                      <FormattedMessage id="Appraisal.manager" />
                    </Breadcrumb.Item>

                    <Breadcrumb.Item
                      active
                    >{`${this.state.targetUser.firstName.toUpperCase()} ${this.state.targetUser.lastName.toUpperCase()} APPRAISAL`}</Breadcrumb.Item>
                  </Breadcrumb>
                </Row>
              )}
            <Row className="mb-4">
              <Col>
                <header className="title text-center">
                  <h4>
                    {this.props.assessorType === EMPLOYEE_ASESSOR_TYPE ? (
                      <FormattedMessage id="Appraisal.preview" />
                    ) : (
                      `${this.state.targetUser.firstName} ${this.state.targetUser.lastName}`
                    )}
                  </h4>
                  {/* <h5>{this.state.appraisal.targetEmployeeTitle}</h5> */}
                  {this.props.assessorType === NO_ASSESSOR_TYPE &&
                    this.state.targetUser.managerName && (
                      <h5>
                        {<FormattedMessage id="EditUserForm.manager" />}
                        {`: ${this.state.targetUser.managerName}`}
                      </h5>
                    )}
                </header>
                <Row>
                  <Col>
                    <ConfigurationPropertiesContext.Consumer>
                      {({ formatDate }) => (
                        <header className="title text-right">
                          <h6>
                            {<FormattedMessage id="Manager.dueDate" />}
                            {`: ${formatDate(this.state.appraisal.dueDate)}`}
                          </h6>
                          {this.state.process && (
                            <h6>
                              {<FormattedMessage id="Appraisal.period" />}
                              {`: ${formatDate(
                                this.state.process.performanceReview.start
                              )} - ${formatDate(
                                this.state.process.performanceReview.end
                              )}`}
                            </h6>
                          )}
                        </header>
                      )}
                    </ConfigurationPropertiesContext.Consumer>
                  </Col>
                  <Col>
                    <header className="title text-left">
                      {this.state.appraisal.status ===
                      APPRAISAL_STATUS_CLOSED ? (
                        <h6>
                          <FormattedMessage id="Appraisal.performanceScore" />:
                          {this.state.appraisal.assessors
                            .find((a) => a.type === FINAL_ASSESSOR_TYPE)
                            .performanceScore.toFixed(2)}
                        </h6>
                      ) : (
                        <h6>
                          {" "}
                          <FormattedMessage id="Appraisal.performanceScore" />{" "}
                          :--{" "}
                        </h6>
                      )}
                      {this.state.appraisal.status ===
                        APPRAISAL_STATUS_CLOSED &&
                      this.state.appraisal.assessors.find(
                        (a) => a.type === FINAL_ASSESSOR_TYPE
                      ).personalDevelopmentScore ? (
                        <h6>
                          <FormattedMessage id="Appraisal.personalDevelopmentScore" />
                          :
                          {this.state.appraisal.assessors
                            .find((a) => a.type === FINAL_ASSESSOR_TYPE)
                            .personalDevelopmentScore.toFixed(2)}
                        </h6>
                      ) : (
                        <h6>
                          <FormattedMessage id="Appraisal.personalDevelopmentScore" />
                          :--
                        </h6>
                      )}
                    </header>
                  </Col>
                </Row>
              </Col>
            </Row>
            {this.getDownloadButton()}
            <Row className="appraisal-preview">
              <Col>
                <GoalTable
                  mode={this.state.mode}
                  extendedAssessmentGoals={this.state.extendedAssessmentGoals}
                  grades={this.state.organizationalGrades}
                  assessorType={this.props.assessorType}
                  addAssessment={this.addAssessment}
                  assessment={this.assessment}
                  type={this.state.goalTypes.find(
                    (goalType) => goalType.id === ORGANIZATIONAL_GOAL_TYPE_ID
                  )}
                  manager={this.state.manager}
                  targetUser={this.state.targetUser}
                  appraisalStatus={this.state.appraisal.status}
                />
                <GoalTable
                  mode={this.state.mode}
                  extendedAssessmentGoals={this.state.extendedAssessmentGoals}
                  grades={this.state.projectGrades}
                  assessorType={this.props.assessorType}
                  addAssessment={this.addAssessment}
                  assessment={this.assessment}
                  type={this.state.goalTypes.find(
                    (goalType) => goalType.id === PROJECT_GOAL_TYPE_ID
                  )}
                  manager={this.state.manager}
                  targetUser={this.state.targetUser}
                  appraisalStatus={this.state.appraisal.status}
                />
                <GoalTable
                  mode={this.state.mode}
                  extendedAssessmentGoals={this.state.extendedAssessmentGoals}
                  grades={this.state.personalDevelopmentGrades}
                  assessorType={this.props.assessorType}
                  addAssessment={this.addAssessment}
                  assessment={this.assessment}
                  type={this.state.goalTypes.find(
                    (goalType) =>
                      goalType.id === PERSONAL_DEVELOPMENT_GOAL_TYPE_ID
                  )}
                  manager={this.state.manager}
                  targetUser={this.state.targetUser}
                  appraisalStatus={this.state.appraisal.status}
                />
                <PersonalDevelopmentCommentSection
                  mode={this.state.mode}
                  assessorType={this.props.assessorType}
                  assessors={this.state.appraisal.assessors}
                  comment={this.state.personalDevelopmentComment}
                  setCommentContent={this.setCommentContent}
                  userId={this.state.targetUser.id}
                  managerFeedback={this.state.managerFeedback}
                  managerName={
                    this.state.targetUser.managerName ===
                    this.state.appraisal.managerName
                      ? this.state.targetUser.managerName
                      : this.state.appraisal.managerName
                  }
                />
                {this.getManagerFeedback()}
                {this.getButtons()}
              </Col>
            </Row>
          </Container>
        )}
      </>
    );
  }
}

export default withRouter(AppraisalManage);

import React, { useState, forwardRef } from "react";
import { Dropdown } from "react-bootstrap";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { FormattedMessage } from "react-intl";
import {
  MANAGER_GRADES_DICTIONARY_NUM,
  GOAL_GRADE,
} from "../../../constants/managerFeedbackGradeDictionary";
import "react-circular-progressbar/dist/styles.css";
import "../../appraisal/grade-selector.css";
import "./manager-grade-selector.css";

export default function ManagerGradeSelector(props) {
  const { grades, readOnly } = props;
  const [currentGrade, setCurrentGrade] = useState(
    props.value ? MANAGER_GRADES_DICTIONARY_NUM[props.value] : 0
  );

  let circleColor = "var(--primary-light)";
  if (currentGrade === 4) circleColor = "var(--primary-dark)";
  if (currentGrade === 5) circleColor = "var(--primary-dark)";

  const circleSpanId = "circle-overlay-" + props.id;

  const circleToggle = forwardRef(({ onClick }, ref) => (
    <CircularProgressbarWithChildren
      value={(currentGrade / GOAL_GRADE) * 100}
      text={currentGrade !== 0 ? currentGrade : ""}
      // strokeWidth={7}
      styles={{
        path: {
          stroke: circleColor,
        },
        text: {
          fill: "var(--primary-dark)",
        },
      }}
    >
      {!readOnly && (
        <span
          id={circleSpanId}
          className="circle-overlay"
          tabIndex="0"
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        ></span>
      )}
    </CircularProgressbarWithChildren>
  ));

  const updateAssessment = (gradeTitle) => {
    /*     const event = {
      target: {
        id: props.id.toString(),
        value: gradeTitle,
        type: "select-one",
      },
    }; */
    props.onChange(gradeTitle);
    // props.assessment(event);
  };

  return (
    <div
      className={`manager-grade-selector ${currentGrade > 0 ? "active" : ""}`}
    >
      <Dropdown
        key={props.id}
        className="center-elemnt"
        style={{
          width: props.circleWidth ? props.circleWidth : "3.125rem",
        }}
      >
        <Dropdown.Toggle as={circleToggle} id="dropdown-custom-components" />
        <div className="dropDownMenu">
          <Dropdown.Menu role="menu">
            {grades &&
              grades.map((grade, key) => (
                <Dropdown.Item
                  key={key}
                  onClick={() => {
                    setCurrentGrade(MANAGER_GRADES_DICTIONARY_NUM[grade.title]);
                    updateAssessment(grade.title);
                  }}
                >
                  <FormattedMessage
                    id={`ManagerFeedbackGrade.${grade.title}`}
                  />
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </div>
      </Dropdown>
      <div className="pt-1">
        {!readOnly && (
          <p
            className="grade-text manager-select-arrow center-elemnt"
            onClick={() => document.getElementById(circleSpanId).click()}
          ></p>
        )}
        <p className="center-elemnt">
          <FormattedMessage id={`ManagerFeedbackGrade.${currentGrade}`} />
        </p>
      </div>
    </div>
  );
}
